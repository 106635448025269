import React from "react"
import {
  AppBar,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from "@material-ui/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faGithub} from "@fortawesome/free-brands-svg-icons"
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  toolbarContainer: {
    padding: "1em 0"
  },
  barStyle: {
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    justifyContent: "space-between"
  },
  iconStyle: {
    color: "#fff",
    alignSelf: "center"
  },
  tabContainer: {
    display: "flex",
    alignContent: "center"
  },
  tabWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  tabs: {
    minWidth: "2.5em"
  }
}))

export default function TopBar(props) {
  const classes = useStyles()
  return (
    <AppBar position={"static"} style={{maxHeight: "88px"}}>
      <Grid container justify={"center"}>
        <Grid item className={classes.barStyle} xs={12} sm={11} lg={8}>
          <Toolbar className={classes.toolbarContainer}>
            <Typography variant={"h2"} noWrap={true}>
              Judith N. Mildner
            </Typography>
          </Toolbar>
          <Grid item className={classes.tabWrapper} >
            <IconButton aria-label={"github"}
                        className={classes.iconStyle}
                        href={"https://github.com/jnmildner"}>
              <FontAwesomeIcon icon={faGithub} />
            </IconButton>
            <IconButton aria-label={"osf"}
                        className={classes.iconStyle}
                        href={"https://osf.io/zr2e6/"}>
              <i className="ai ai-osf" />
            </IconButton>
            <IconButton aria-label={"google scholar"}
                        className={classes.iconStyle}
                        href={"https://scholar.google.com/citations?user=mqCq3poAAAAJ&hl=en&oi=ao"}>
              <i className="ai ai-google-scholar"/>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </AppBar>
  )
}