import React from 'react'
import './academicons-1.8.6/css/academicons.min.css'
import { Grid, Typography} from "@material-ui/core"
import TopBar from "./top-bar"
import {makeStyles} from "@material-ui/styles"

const useStyles = makeStyles({
  image: {
    width: "90%",
    borderRadius: "50%",
    maxWidth: "300px"
  },
  imageContainer: {
    flex: "1 0 50%",
    textAlign: "center"
  },
  textContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: "1 0 50%",
    flexDirection: "column"
  }
})

function App() {
  const classes = useStyles()
  return (
    <Grid container>
      <TopBar />
      <Grid container alignItems={"center"} style={{paddingTop: "10vh"}}
            justify={"center"} direction={"column"}>
      <Grid item xs={12} sm={11} lg={8}>
        <Grid container direction={"row"} wrap={"nowrap"}>
          <Grid item className={classes.imageContainer}>
            <img src={"assets/IMG_JNM.jpg"} className={classes.image}/>
          </Grid>

        <Grid item className={classes.textContainer}>
          <Typography gutterBottom>
            I am a graduate student at the Department of Psychology at Princeton
            University. I work with Professor Diana Tamir at the <a
            href="psnlab.princeton.edu">
            Princeton Social Neuroscience Lab.</a>
          </Typography>
          <Typography gutterBottom>
            My research focuses mostly on the relations between people’s
            spontaneous thoughts, like mind wandering and creativity, and the
            social world. I employ various different methods in my research,
            including behavioral experiments (both online and in person),
            neuroimaging (fMRI), and text analysis.
          </Typography>
          <Typography gutterBottom>
            I have a BSc in Human Development from Cornell University, and a MSc
            in Sport Sciences from the University of Groningen (The Netherlands).
          </Typography>
        </Grid>
        </Grid>
      </Grid>
      </Grid>
    </Grid>
  );
}

export default App;
