import 'typeface-contrail-one'
import 'typeface-montserrat'
import { createMuiTheme } from "@material-ui/core"

export default createMuiTheme({
  typography: {
    fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    h1: {
      fontFamily: '"Contrail One", "Helvetica", "Arial", sans-serif',
    },
    h2: {
      fontFamily: '"Contrail One", "Helvetica", "Arial", sans-serif',
    },
    h3: {
      fontFamily: '"Contrail One", "Helvetica", "Arial", sans-serif',
    },
    h4: {
      fontFamily: '"Contrail One", "Helvetica", "Arial", sans-serif',
    },
    h5: {
      fontFamily: '"Contrail One", "Helvetica", "Arial", sans-serif',
    },
    h6: {
      fontFamily: '"Contrail One", "Helvetica", "Arial", sans-serif',
    },
    subtitle1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    subtitle2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    body1: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    },
    body2: {
      fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
    }
  }
})